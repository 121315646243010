import React, { useState } from 'react';
import {
  Button,
  Checkbox, DatePicker, Form, Input, notification, Select, Tabs, TimePicker,
} from 'antd';
import { useMutation } from '@tanstack/react-query';
import preorder from '../../utils/preorderRest';

function CreateSlot({
  queues, days, refetch, closeDrawer,
}) {
  const [forAll, setForAll] = useState(false);
  const [overwrite, setOverwrite] = useState(false);
  const [activeTab, setActiveTab] = useState('single');
  const [selectedQueues, setSelectedQueues] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [calendarDay, setCalendarDay] = useState();
  const [populate, setPopulate] = useState({
    interval: 15,
    count: 5,
  });
  const { mutate, isLoading } = useMutation({
    mutationFn: (variables) => preorder.createSlot(variables),
    onSuccess: (status) => {
      if (status === 'failed') {
        notification.error({
          message: 'Не удалось создать слоты',
        });
      } else {
        notification.success({
          message: 'Слоты успешно созданы',
        });
        refetch();
        closeDrawer();
      }
    },
    onError: () => {
      notification.error({
        message: 'Не удалось создать слоты',
      });
    },
  });

  const selectDate = (date) => {
    const day = date && days?.find(
      (el) => (el.monthNumber === (date.month() + 1) && el.dayOfMonth === date?.date()),
    );
    if (day) {
      setSelectedDate(date?.format('YYYY.MM.DD'));
      setCalendarDay(day);
    }
  };

  const changeTab = (tab) => {
    setActiveTab(tab);
  };

  const selectTime = (value) => {
    setSelectedTime(value?.format('HH:mm'));
  };

  const selectQueues = (values) => {
    setSelectedQueues(values);
  };

  const selectTimeInterval = (
    _timeInterval,
    timeIntervalString,
  ) => {
    setSelectedTime(timeIntervalString);
  };

  const selectSlotCount = (e) => {
    const slotData = { ...populate };
    slotData[e.target.name] = Number(e.target.value);
    setPopulate(slotData);
  };

  const createSlots = () => {
    const qArr = [...selectedQueues];
    if (forAll) {
      qArr.splice(0, qArr.length);
      queues?.forEach((el) => {
        if (el.qId !== 0) {
          qArr.push(el.qId);
        }
      });
    }
    if (calendarDay) {
      if (activeTab === 'single') {
        mutate({
          dayId: calendarDay.dayId,
          populate: false,
          data: {
            begin: `${selectedDate} ${selectedTime}`,
            end: `${selectedDate} ${selectedTime}`,
            dayId: calendarDay.dayId,
            queues: qArr,
          },
        });
      } else if (activeTab === 'multiple') {
        mutate({
          dayId: calendarDay.dayId,
          populate: true,
          data: {
            begin: `${selectedDate} ${selectedTime && selectedTime[0]}`,
            end: `${selectedDate} ${selectedTime && selectedTime[1]}`,
            dayId: calendarDay.dayId,
            queues: qArr,
            replace: overwrite,
            amount: populate.count,
            step: populate.interval,
          },
        });
      }
    }
  };

  const tabItems = [
    {
      key: 'single',
      label: 'Одиночное добавление слота',
      children: (
        <div className="preorder__slot__date">
          <div className="preorder__slot__date__select">
            <DatePicker
              format="DD.MM.YYYY"
              placeholder="Выберите дату"
              onChange={selectDate}
              style={{ width: '100%' }}
              showNow={false}
            />
            <TimePicker
              format="HH:mm"
              minuteStep={5}
              placeholder="Выберите время"
              onChange={selectTime}
              style={{ width: '100%' }}
              showNow={false}
            />
          </div>
          <Button
            onClick={createSlots}
            type="primary"
            disabled={!selectedTime || (selectedQueues.length === 0 && !forAll)}
            loading={isLoading}
          >
            Добавить слоты
          </Button>
        </div>
      ),
    },
    {
      key: 'multiple',
      label: 'Множественное добавление слотов',
      children: (
        <Form style={{ width: '600px' }} layout="vertical">
          <Form.Item label="Выберите день">
            <DatePicker
              style={{ width: '200px' }}
              format="DD.MM.YYYY"
              showNow={false}
              placeholder="Выберите день"
              onChange={selectDate}
            />
          </Form.Item>
          <div className="preorder__form__row">
            <Form.Item label="Интервал времени (минуты)">
              <Input
                name="interval"
                type="number"
                onChange={selectSlotCount}
                defaultValue={15}
                step={5}
                min={5}
              />
            </Form.Item>
            <Form.Item label="Кол-во слотов на одно время">
              <Input
                name="count"
                type="number"
                onChange={selectSlotCount}
                defaultValue={5}
                min={1}
              />
            </Form.Item>
          </div>
          <Form.Item label="Интервал времени">
            <TimePicker.RangePicker
              onChange={selectTimeInterval}
              format="HH:mm"
              style={{ width: '320px' }}
              minuteStep={5}
              showNow={false}
            />
          </Form.Item>
          <Form.Item>
            <Checkbox
              checked={overwrite}
              onChange={(e) => setOverwrite(e.target.checked)}
            >
              Перезаписывать существующие слоты
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Button
              disabled={!selectedTime || !Array.isArray(selectedTime) || (selectedQueues.length === 0 && !forAll)}
              onClick={createSlots}
              type="primary"
              loading={isLoading}
            >
              Добавить слоты
            </Button>
          </Form.Item>
        </Form>
      ),
    },
  ];

  return (
    <div>
      <Form style={{ width: '600px' }} layout="vertical">
        <Form.Item label="Список услуг для добавления слотов">
          <Select
            disabled={forAll}
            style={{ width: '100%' }}
            mode="multiple"
            onChange={selectQueues}
            placeholder="Выберите услуги"
            optionFilterProp="label"
          >
            {queues?.map((queue) => (
              <Select.Option label={queue.qFullName} key={queue.qId} value={Number(queue.qId)}>
                {queue.qFullName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      <Checkbox
        checked={forAll}
        onChange={(e) => setForAll(e.target.checked)}
      >
        Добавить слоты по всем услугам
      </Checkbox>
      <Tabs onChange={changeTab} style={{ marginTop: '20px' }} items={tabItems} />
    </div>
  );
}

export default CreateSlot;
