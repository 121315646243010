import axios from 'axios';
import dayjs from 'dayjs';
import registerSlot from './abtClient';

const DEBUG = process.env.NODE_ENV === 'development';
const url = DEBUG ? 'https://demo.a-7.tech' : window.location.origin;
const token = localStorage.getItem('token');

const preorderRest = {
  getQueue: async () => {
    const res = await axios.post(`${url}/server/public/preorder/queues/?action=get&token=${token}`, {});
    return res.data.data;
  },
  getDays: async () => {
    const res = await axios.post(`${url}/server/api/dcalendar/?action=get&token=${token}&year=${dayjs().format('YYYY')}`, {});
    return res.data.data;
  },
  getSlots: async (dates) => {
    const res = await axios.post(`${url}/server/api/dpafullcalendarslot/?action=get&token=${token}`, {
      data: {
        dateFrom: dates[0],
        dateTo: dates[1],
      },
    });
    return res.data.data;
  },
  createSlot: async (data) => {
    const { populate } = data;
    const res = populate
      ? await axios.post(`${url}/server/api/dcalendar/${data.dayId}?action=populate-v2&token=${token}`, {
        data: { ...data.data },
      })
      : await axios.post(`${url}/server/api/dpacalendarslot/?action=add-v2&token=${token}`, {
        data: { ...data.data, calendarday_id: data.dayId },
      });
    return res.data.status;
  },
  copySlot: async (data) => {
    const res = await axios.post(`${url}/server/api/dcalendar/${data.dayId}/?action=copy-v2&token=${token}`, {
      data: { ...data.data },
    });
    return res.data.status;
  },
  deleteSlots: async (data) => {
    const res = await axios.post(`${url}/server/api/dcalendar/${data.dayId}/?action=clear-v2&token=${token}`, {
      data: { ...data.data },
    });
    return res.data.status;
  },
  rewriteSlots: async (data) => {
    const res = await axios.post(`${url}/server/api/dcalendar/${data.dayId}/?action=reset-day&token=${token}`, {
      data: { ...data.data },
    });
    return res.data.status;
  },
  registerSlot: async (data) => registerSlot(url, data),
  rewriteSlot: async (data) => {
    const res = await axios.post(`${url}/server/api/dpacalendarslot/90380/?action=clear&token=${token}`, {
      data,
    });
    return res.data.status;
  },
  deleteSlot: async (slotId) => {
    const res = await axios.post(`${url}/server/api/dpacalendarslot/${slotId}/?action=delete&token=${token}`, {});
    return res.data.status;
  },
  saveSlot: async (data) => {
    const res = await axios.post(`${url}/server/api/dpacalendarslot/${data.calendarCellId}/?action=update&token=${token}`, {
      data: { ...data },
    });
    return res.data.status;
  },
};

export default preorderRest;
