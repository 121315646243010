const lexicon = {
  а: 'a',
  б: 'b',
  в: 'v',
  г: 'g',
  д: 'd',
  е: 'e',
  ё: 'e',
  ж: 'j',
  з: 'z',
  и: 'i',
  й: 'j',
  к: 'k',
  л: 'l',
  м: 'm',
  н: 'n',
  о: 'o',
  п: 'p',
  р: 'r',
  с: 's',
  т: 't',
  у: 'u',
  ф: 'f',
  х: 'h',
  ц: 'c',
  ч: 'ch',
  ш: 'sh',
  щ: 'sch',
  ъ: '',
  ы: 'y',
  ь: '',
  э: 'ae',
  ю: 'yu',
  я: 'ya',
  a: 'ai',
  b: 'bi',
  c: 'ci',
  d: 'di',
  e: 'ii',
  f: 'ef',
  g: 'gi',
  h: 'hi',
  i: 'ii',
  j: 'ji',
  k: 'kei',
  l: 'el',
  m: 'em',
  n: 'en',
  o: 'oo',
  p: 'pi',
  q: 'kju',
  r: 'ar',
  s: 'es',
  t: 'ti',
  u: 'ju',
  v: 'vi',
  w: 'dablju',
  x: 'eks',
  z: 'zed',
};

module.exports = (letter, dict = lexicon, parser) => {
  console.log('letter === ', letter);
  const toTranslate = letter.toString().toLowerCase();
  if (toTranslate.length > 1) return toTranslate;

  const shouldTranslate = dict[toTranslate];

  return (typeof parser === 'function') ? parser(shouldTranslate) : shouldTranslate;
};
